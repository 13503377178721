<template>
  <v-form @submit.prevent="submit" ref="form" class="profile__user-form">
    <AvatarUploader
      :item="form[PROFILE_FORM_FIELD_API_ALIASES.PHOTO]"
      @upload="handleNonSyncFieldInput(PROFILE_FORM_FIELD_API_ALIASES.PHOTO, arguments[0])"
      :disabled="disabled"
      class="profile__avatar"
    />
    <div class="profile__user-form-tabs">
      <span
        class="profile__user-form-tab"
        :class="{ 'profile__user-form-tab--active': activeTab === 'info' }"
        @click="handleEmitTab('info')"
        >Information</span
      >
      <span
        class="profile__user-form-tab"
        :class="{ 'profile__user-form-tab--active': activeTab === 'pass' }"
        @click="handleEmitTab('pass')"
        >Change Password</span
      >
    </div>
    <FormGroup :disabled="disabled" class="profile__form-group profile__form-group--info">
      <v-text-field
        :value="form[PROFILE_FORM_FIELD_API_ALIASES.NAME]"
        @input="
          handleFieldInput('form', {
            ...form,
            [PROFILE_FORM_FIELD_API_ALIASES.NAME]: arguments[0],
          })
        "
        :disabled="disabled"
        label="Full name"
        :rules="['required']"
      />
      <v-text-field
        :value="form[PROFILE_FORM_FIELD_API_ALIASES.EMAIL]"
        @input="
          handleFieldInput('form', {
            ...form,
            [PROFILE_FORM_FIELD_API_ALIASES.EMAIL]: arguments[0],
          })
        "
        :disabled="disabled"
        label="Email Adress"
        :rules="['required', 'email']"
      />
    </FormGroup>
    <div class="profile__footer">
      <v-btn :disabled="disabled" class="profile__footer-button" text @click="$router.go(-1)">
        Cancel
      </v-btn>
      <v-btn :disabled="disabled" @click="handleSubmitButton" class="profile__footer-button">
        Save
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { PROFILE_FORM_FIELD_API_ALIASES } from '@/utils/constants';
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';

const FormGroup = () => import('@/components/FormGroup');
const AvatarUploader = () => import('@/components/AvatarUploader');

export default {
  name: 'ProfileUserForm',
  components: {
    AvatarUploader,
    FormGroup,
  },
  mixins: [syncForm, validateForm],
  props: {
    activeTab: String,
    disabled: {
      type: [Boolean],
      default: () => false,
    },
    requestInProgress: {
      type: [Boolean],
      default: () => false,
    },
    form: {
      type: Object,
      default: () => '',
    },
  },
  data: () => ({
    PROFILE_FORM_FIELD_API_ALIASES,
  }),
  methods: {
    handleEmitTab(tab) {
      this.$emit('changeTab', tab);
    },
    handleSubmitButton() {
      this.$emit('submit');
    },
    submitForm() {
      const valid = this.validateForm();
      if (valid) {
        this.$emit('submit', {
          login: this.login,
          password: this.password,
        });
      }
    },
    validate() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
      throw new Error("The form wasn't mounted");
    },
  },
};
</script>

<style lang="scss" scope>
.profile__user-form {
  width: 100%;
}
.profile__form-group {
  width: 100%;
}
.profile__avatar {
  margin: 34px 0 23px;
}
.profile__user-form-tab {
  color: #949494;
  font-size: 18px;
  font-weight: 500;
  padding: 0 6px;
  line-height: 42px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.3s all ease-in-out;
  &:hover {
    color: #000000;
  }
  &--active {
    color: #000000;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, #7aee5d 0%, #1fe08d 100%);
    }
  }
}
.profile__footer-button {
  width: 165px;
}
.profile__footer {
  margin: 24px 0 0;
  display: flex;
  justify-content: space-around;
}
</style>
